$(document).ready(function(){
    $('#main-slider').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        autoplay:true,
        autoplayHoverPause:true,
        navText:['<span class="glyphicon glyphicon-triangle-left" aria-hidden="true"></span>','<span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span>'],
        responsive:{
            0:{
                items:1
            }
        }
    });

    new WOW().init();

    $(".popup").colorbox({rel:'popup', transition:"fade"});
});

